import Hls from 'hls.js';
import * as basicLightbox from 'basiclightbox';

const load_video = (el) => {
  if (el.dataset.loaded) return;

  const src = `https://stream.mux.com/${el.dataset.muxPlaybackId}.m3u8?redundant_streams=true`;

  if (Hls.isSupported()) {
    var hls = new Hls();
    hls.loadSource(src);
    hls.attachMedia(el);
  } else if (el.canPlayType('application/vnd.apple.mpegurl')) {
    el.src = src;
  }

  el.dataset.loaded = true;
};

window.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('video[data-mux-playback-id]').forEach((video) => load_video(video));

  document.querySelectorAll('[data-lightbox]').forEach((el) => {
    el.addEventListener('click', (e) => {
      e.preventDefault();
      basicLightbox.create(`<img src="${el.dataset.imageSrc}">`).show();
    });
  });

  const scroller = InfiniteScroll.data('.posts');
  if (scroller) {
    scroller.on('append', (body, path, items, response) => {
      document.querySelectorAll('video[data-mux-playback-id]').forEach((video) => load_video(video));
    });
  }
});
